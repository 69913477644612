<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="biometria"
    :hasExam="hasAttendanceValues('exam')"
    @getData="importAttendanceData(null, $event)"
  >
    <div class="form-group" v-for="(field, index) of form.value" :key="index">
      <label for="fields">{{field.title}}</label>

      <b-row>
        <b-col>
          <b-row >
            <b-col cols="6">
              <div class="form-group mb-0">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <input
                    autocomplete="off"
                    :value="field.olhoDireito"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireito', el.target.value, index)"
                    @blur="updateMedicalRecord(form)"
                    id="biometria-direito"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group mb-0">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <input
                    autocomplete="off"
                    :value="field.olhoEsquerdo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdo', el.target.value, index)"
                    @blur="updateMedicalRecord(form)"
                    id="biometria-esquerdo"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-row class="padding-row">
          <button
            class="btn blue-underline"
            @click="copyEyeValues(index)"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <Copy class="icon" />
          </button>
          <div v-if="canEdit" class="new-medical-report">
            <Icon tooltip="Deletar" v-if="field.title != 'AL' && field.title != 'ACD'">
              <Trash class="icon trash" @click="removeFields(field)"/>
            </Icon>
          </div>
        </b-row>
      </b-row>
    </div>
    <b-row>
      <b-col cols="12" v-if="!addNew">
        <b-row>
          <Icon tooltip="Adicionar novo campo">
            <button
              class="btn blue-underline button-copy"
              @click="addNew = true"
            >
            <Plus class="icon"/>
              Adicionar novo campo
            </button>
          </Icon>
        </b-row>
      </b-col>
      <b-row style="width: 100%" v-else>
        <b-col cols="6" >
          <div class="form-group" >
            <validation-provider
              name="lentes"
              :rules="{ required: false }"
              v-slot="{ errors, touched }"
            >
              <multiselect
                v-model="selectedTitle"
                :options="titles"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                placeholder="Buscar e selecionar lente "
                class="with-border"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="addNewField(props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      <span class="highlighted">Adicionar lente personalizada {{ props.search ? `"${props.search}"` : '' }}</span>
                    </div>
                  </li>
                </template>
              </multiselect>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="2" >
          <b-button
            block
            variant="primary"
            class="h-38"
            :disabled="!canEdit"
            @click="addNewField(selectedTitle)"
          >
            Adicionar
          </b-button>
        </b-col>
      </b-row>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Icon: () => import('@/components/General/Icon'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.biometria,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  data() {
    return {
      useStandard: false,
      addNew: false,
      selectedTitle: null,
      titles:[],
      user: JSON.parse(localStorage.getItem('user')),
    }
  },
  async mounted() {
    await this.getAllLentes()
    await this.getStandards()
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/biometria', ['handleFields', 'handleProps', 'addValue', 'setDefaultValue']),
    updateForm(key, value, index) {
      this.form.value[index][key] = value
      this.handleProps({ key: 'value', value: this.form.value })
    },
    copyEyeValues(index) {
      this.updateForm('olhoEsquerdo', this.form.value[index].olhoDireito, index)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    async getStandards() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getStandards(this.user.id, "biometria")
        if (response.data[0]?.active){
          this.setDefaultValue(response.data[0]?.value)
          return
        }
        this.setDefaultValue()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getAllLentes(){
      const loading = this.$loading.show();
      try {
        const response = await this.api.getFillingOptions('biometria-lente')
        this.titles = response.data.map(data => {
          return data.value
        })
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    addNewField(title) {
      if(!title) return
      const field = {title: title, olhoDireito: null, olhoEsquerdo: null}
      this.addValue(field)
      this.addNew = false
      this.selectedTitle = null
      this.updateMedicalRecord(this.form)
    },
    removeFields(fields) {
      const index = this.form.value.indexOf(fields)
      this.form.value.splice(index, 1)
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach(value => {
        value.olhoDireito = null
        value.olhoEsquerdo = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const biometria = data.find(el => { return el.type === 'biometria'})

      if (!biometria?.id) return
      Object.keys(biometria.value).map(key => {
        if (biometria.value[key]) {
          this.updateForm('olhoDireito', biometria.value[key]?.olhoDireito, key)
          this.updateForm('olhoEsquerdo', biometria.value[key]?.olhoEsquerdo,key)
        }
      })
      await this.updateMedicalRecord(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>

.form-group {

    font-weight: 600;
    font-size: 14px;
    color: var(--blue-700);
    margin-bottom: 4px !important;
}

  .padding-row{
    padding-right: 8px !important;
  }
  .new-medical-report {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    gap: 10px;

    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }

      &.close {
        fill: var(--type-active);
      }
    }
  }
  .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin: 4px 12px 0px 4px;
      cursor: pointer;
    }
</style>
